.page-inner {
  background-color: $sh_black;
  border: 2px solid $sh_cyan_62_2;
}

.page {
  background: #111 url("/images/Systemhelden-page-bg.jpg") no-repeat 50% 0;
}

@media (min-width: 576px) {
  .page {
    padding: 11px 0 0;
    background-size: 648px;
  }
}
@media (min-width: 768px) {
  .page {
    padding: 14px 0 0;
    background-size: 864px;
  }
}
@media (min-width: 992px) {
  .page {
    padding: 20px 0 0;
    background-size: 1152px;
  }
}

.navbar {
  background: url("/images/SH_heading_back.gif") repeat-x;
  border-top: 2px solid #3d3d3d;
  border-bottom: 2px solid #262626;
}

.nav-link:link,
.nav-link:visited,
.sh-glow,
.sh-glow > a:link,
.sh-glow > a:visited {
  text-shadow: rgba(0, 255, 255, 1) 0 0 6px;
}

.nav-link:hover,
.sh-glow > a:hover {
  text-shadow: rgba(255, 85, 0, 1) 0 0 6px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary;
}
